<template>
  <div class="page translation batch">
    <div class="ibox ">
      <div class="ibox-content">
        <div class="table-responsive">
          <table
            ref="list"
            class="table table-stripped toggle-arrow-tiny"
            data-paging="false"
          >
            <thead>
              <tr>

              </tr>
            </thead>
            <tbody>
              <tr class="fields text-left">
                <div class="form-group row right">
                  <label class="col-sm-2 col-form-label"
                    >language.json 파일 업로드</label
                  >
                  <div class="col-sm-10">
                    <div class="input-group batch-upload-input">
                      <div class="input form-control">
                        <div class="custom-file">
                          <label class="custom-file-label">
                            <input
                              type="file"
                              class="custom-file-input"
                              @change="onChangeFile"
                            />
                            <label class="custom-file-label">{{
                              uploadFile && uploadFile.name
                            }}</label>
                          </label>
                        </div>
                      </div>                      
                    </div>
                  </div>
                </div>
              </tr>
              <div class="hr-line-dashed"></div>
              <tr>
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label"
                    >번역 여부</label
                  >
                  <div class="col-sm-10">
                    <input-checkbox class="checkbox" :name="'translated_check'" :expected="true" :value="translated" @input="translated = !translated" :ripple="true">{{translated ? '번역됨' : '번역 안 됨'}}</input-checkbox>
                  </div>
                </div>
              </tr>
              <div class="hr-line-dashed"></div>
              <tr>
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label"
                    >사용 여부</label
                  >
                  <div class="col-sm-10">
                    <input-checkbox class="checkbox" :name="'using_check'" :expected="true" :value="using" @input="using = !using" :ripple="true">{{using ? '사용중' : '사용 안 함'}}</input-checkbox>
                  </div>
                </div>
              </tr>
            </tbody>
          </table>

          <div class="hr-line-dashed"></div>

          <div class="form-group row">
            <div class="actions">
              <div class="left">
                <button class="btn btn-white btn-sm" @click="onClickBack">Back</button>
              </div>

              <div class="right">                
                <button
                  class="btn btn-success btn-xs"
                  @click="onUpload"
                  :disabled="sending"
                >
                  업로드
                </button>       
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import Pagination from "@/components/elements/Pagination";

export default {
  mounted() {
    this.$data.mode = this.$route.meta.mode;
    this.$data.model = this.$route.meta.model;
  },
  data() {
    return {
      columns: 3,
      mode: "unset",
      model: {},
      page: 1,
      size: 10,
      total: 0,
      data: {},
      // items: [],
      translated: false,
      using: false,
      uploadFile: null,
      sending: false,    
      timestamp: +new Date(),
    };
  },
  watch: {},
  methods: {
    displayStatus(status) {
      if (status === "success") {
        return "성공";
      }

      return `실패`;
    },

    onChangeFile(e) {
      if (e.target.files && e.target.files.length > 0) {
        this.$data.uploadFile = e.target.files[0];
      }
    },

    onUpload(e) {
      this.$data.sending = true;

      const formData = new FormData();

      formData.append("file", this.uploadFile);
      formData.append("translated", JSON.stringify(this.translated))
      formData.append("using", JSON.stringify(this.using))
  

      // this.$data.items = [];

      this.$store
        .dispatch("translation/batch", formData)
        .then((data) => {
          let { error, items } = data;
          if (error) {
            this.$toast.error(error.message);
            return;
          }

          this.$data.items = items;
          this.$toast.success('생성되었습니다.');

          this.$data.data = data;
			    this.$data.timestamp = +new Date();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        })
        .finally(() => {
          this.$data.sending = false;
        });
    },

    onClickBack() {
      this.$router.back();
    },
  },
  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
  .row{
    align-items: center;
  }

  .custom-file{

    &-label{
      border: 0;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  .table{
    margin-top: 1.25rem;
  }

</style>
